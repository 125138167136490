import axios from "axios";
// const baseUrl = "http://localhost:5000";
const baseUrl = "https://menttech-website.onrender.com";
export const BASE_CALL = {
  post: async (url, payload) => await axios.post(baseUrl + url, payload),
  put: async (url, payload) =>
    await axios.put(baseUrl + url + `/${payload.id}`, payload),
  get: async (url, params) =>
    await axios.get(baseUrl + url, {
      params
    }),
  delete: async (url) => await axios.delete(baseUrl + url)
};
