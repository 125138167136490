import React, { useEffect, useState } from "react";
import PageContainer from "src/components/container/PageContainer";
import DashboardCard from "src/components/shared/DashboardCard";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { API_CALL } from "src/services/APICalls";
import { showDate } from "src/components/commonfunctions";
import DeleteIcon from "@mui/icons-material/Delete";
import telegramICon from "../../assets/images/logos/telegramicon.webp"
import WhatsappIcon from "../../assets/images/logos/whatsappicon.webp"
import DiscordIcon from "../../assets/images/logos/discordicon.png"
import SkypeIcon from "../../assets/images/logos/skypeicon.png"
import LinkedinIcon from "../../assets/images/logos/linkedinicon.png"
import FileOpenIcon from '@mui/icons-material/FileOpen';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  NativeSelect,
  Select,
  Typography
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import DescriptionModal from "./DescriptionModal";
import { toast } from "react-toastify";
import CustomLoader from "src/components/custom-scroll/CustomLoader";
import { MyTheme } from "src/layouts/customTheme";
function AllSubscriber() {
  //   let ActionButton = <Button variant="contained">Add</Button>;
  const [tableData, setTableData] = useState([]);
  const [loader, setLoader] = useState(false);
  const reloadPage = () => setLoader(!loader);
  // const [status, setStatus] = useState({});
  const [filter, setFilter] = useState({ status: "All", search: "",type:"All" });

  useEffect(() => {
    (async () => {
      try {
        console.log(filter);
        let { data } = await API_CALL.sunscription.get(filter);
        console.log(data);
        // setTableData([...data.data, ...data.data, ...data.data]);
        setTableData(data.data);
      } catch (error) {
        toast.error(error.response.data.message);
        setTableData([]);
      }
    })();
  }, [loader, filter]);

  return (
    <PageContainer title="Ment Tech Subscribers" description="">
      <DashboardCard title="Subscribers">
        <Grid container justifyContent="space-between">
          <Grid item xs={12} md={3} textAlign="left">
            <div
              style={{
                position: "relative",
                display: "flex",
                alignItems: "center"
              }}
            >
              <input
                className="searchInput"
                value={filter.search}
                onChange={(e) =>
                  setFilter({ ...filter, search: e.target.value })
                }
              />
              <SearchIcon
                style={{ position: "absolute", right: 10 }}
                fontSize="large"
              />
            </div>
          </Grid>
          {/* <Grid item xs={6} md={2} textAlign="right">
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.status}
                // label="Status"
                onChange={(e) => {
                  setFilter({ ...filter, status: e.target.value });
                }}
              >
                {[
                  "All",
                  "New Enquiry",
                  "Won",
                  "In-Negotiation",
                  "Followed-up",
                  "Responded",
                  "Job Enquiry",
                  "Lost",
                  "Scam",
                  "Spam"
                ].map((item, key) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid> */}
          <Grid item xs={6} md={4} textAlign="right" display="flex" gap={1}>
             <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.type}
                // label="Status"
                
                onChange={(e) => {
                  setFilter({ ...filter, type: e.target.value });
                }}
              >
                {[
                  "All",
                  "PRODUCT",
                  "CAREER",
                  "DEMO",
                ].map((item, key) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={filter.status}
                // label="Status"
                onChange={(e) => {
                  setFilter({ ...filter, status: e.target.value });
                }}
              >
                {[
                  "All",
                  "New Enquiry",
                  "Won",
                  "In-Negotiation",
                  "Followed-up",
                  "Responded",
                  "Job Enquiry",
                  "Lost",
                  "Scam",
                  "Spam"
                ].map((item, key) => {
                  return (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        {tableData?.length == 0 ? (
          <Typography color="red" textAlign="center" fontSize={20}>
            No Data Found
          </Typography>
        ) : (
          <BasicTable rows={tableData} reloadPage={reloadPage} />
        )}
      </DashboardCard>
    </PageContainer>
  );
}

const BasicTable = ({ rows, reloadPage }) => {
  // const [s, sets] = useState(second)
  const [open, setOpen] = React.useState({
    open: false,
    content: ""
  });

  const title = {
    fontWeight: "bold",
    fontSize: "17px"
  };

  return (
    <TableContainer sx={{ maxHeight: "70vh" }}>
      <Table stickyHeader sx={{ minWidth: 650 }} aria-label="sticky table">
        <DescriptionModal open={open} setOpen={setOpen} />

        <TableHead>
          <TableRow>
            <TableCell style={title}>Sr</TableCell>

            <TableCell style={title} align="left ">
              Name
            </TableCell>

            <TableCell style={title} align="left">
              Platform
            </TableCell>
            <TableCell style={title} align="left">
              File
            </TableCell>
      
            <TableCell style={title} align="left">
              Email
            </TableCell>

            <TableCell style={title} align="center">
              Date
            </TableCell>
            <TableCell style={title} align="center">
              Status
            </TableCell>
            <TableCell style={title} align="center">
              Action
            </TableCell>
            {/* <TableCell align="center">Protein&nbsp;(g)</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows?.map((item, key) => {
            return (
              <SingleRow2
                row={item}
                key={item?._id}
                setOpen={setOpen}
                reloadPage={reloadPage}
                index={key}
              />
            );
          })}
         
        </TableBody>
      </Table>
    </TableContainer>
  );
};


const SingleRow2 = ({ setOpen, reloadPage, index, row }) => {
  const [isLoading, setIsLoading] = useState(false);
  const updateStatus = async (e) => {
    try {
      setIsLoading(true);
      const status = e.target.value;
      const { data } = await API_CALL.sunscription.update({
        status,
        id: row?._id
      });
      if (data.success) {
        toast.success(data.message);
        reloadPage();
        setIsLoading(false);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    }
  };

  const DeleteEnquiry = async () => {
    try {
      let confirm = window.confirm("Are  you sure you want to delete it");
      if (!confirm) return null;
      const { data } = await API_CALL.sunscription.delete({ id: row._id });
      if (data.success) {
        toast.success(data.message);
        reloadPage();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const getIcon=text=>{
    if(text=="Telegram") return telegramICon
    if(text=="Whatsapp") return WhatsappIcon
    if(text=="Discord") return DiscordIcon
    if(text=="Linkedin") return LinkedinIcon
    if(text=="Skype") return SkypeIcon
  }

  return (
    <TableRow
      hover
      role="checkbox"
      tabIndex={-1}
      key={row.code}
      // style={{ borderBottom: "1px solid gray" }}
    >
      <TableCell className="tablebox">{index + 1}</TableCell>
      <TableCell className="tablebox">{row?.fullName}</TableCell>
      <TableCell className="tablebox" >
        <Typography display="flex" alignItems="center">

   {row?.platform  && <img src={getIcon(row?.platform)} width="20px" style={{marginRight:"10px"}}/>}
        {row?.contact}
        </Typography> 
        </TableCell>
      <TableCell className="tablebox">{row?.resume ? <a href={row?.resume} target="_blank">
        <FileOpenIcon />
      </a> :"NA"}</TableCell>
      <TableCell className="tablebox">{row?.email}</TableCell>
      <TableCell className="tablebox">{showDate(row?.createdAt)}</TableCell>
      <TableCell className="tablebox">
        <FormControl fullWidth>
          {/* <InputLabel variant="standard" htmlFor="uncontrolled-native">
            Age
          </InputLabel> */}
          <NativeSelect
            defaultValue={row?.status}
            color="success"
            style={{
              color:
                row?.status == "New Enquiry"
                  ? "green"
                  : row?.status == "Scam" ||
                    row?.status == "Spam" ||
                    row?.status == "Lost"
                  ? "red"
                  : row?.status == "Won"
                  ? "green"
                  : MyTheme.bgColor1
            }}
            // variant="filled"
            onChange={updateStatus}
          >
            {[
              "Won",
              "In-Negotiation",
              "Followed-up",
              "Responded",
              "New Enquiry",
              "JOB",
              "Lost",
              "Scam",
              "Spam"
            ].map((item, key) => {
              return (
                <option key={item} value={item}>
                  {item}
                </option>
              );
            })}
          </NativeSelect>
        </FormControl>
      </TableCell>
      <TableCell className="tablebox">
        <Button
          variant="outlined"
          onClick={() =>
            setOpen({
              open: true,
              content: row
            })
          }
        >
          View
        </Button>
      </TableCell>
      {/* <TableCell className="tablebox">
        <DeleteIcon onClick={DeleteEnquiry} />
      </TableCell> */}
    </TableRow>
  );
};
export default AllSubscriber;
