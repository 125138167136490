import { BASE_CALL } from "./baseCall";

export const API_CALL = {
  sunscription: {
    get: async (payload) => await BASE_CALL.get("/subscriber/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/subscriber/delete/" + payload.id),
    update: async (payload) =>
      await BASE_CALL.put("/subscriber/update", payload)
  },
  users: {
    create: async (payload) => BASE_CALL.post("/user/create", payload),
    getByWallet: async (walletAddress) =>
      await BASE_CALL.get("/user/get-by-wallet", { walletAddress }),
    get: async (payload) => await BASE_CALL.get("/user/get", payload)
  },
  lead: {
    get: async (payload) => await BASE_CALL.get("/lead/get", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/lead/delete/" + payload.id)
  },
  session: {
    get: async (payload) => await BASE_CALL.get("/session", payload),
    group: async (payload) => await BASE_CALL.get("/session/group", payload),
    delete: async (payload) =>
      await BASE_CALL.delete("/session/delete/" + payload.id)
  }
};
